import React from "react"
import NewLayout from "../components/new_layout"
import { graphql } from "gatsby"
import MainSection50 from "../components/Layout/main-section-50"
import MainSection100 from "../components/Layout/main-section-100"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import GroupImageGroup from "../components/v2024/image/group-image-group"
import ListHighlight from "../components/v2024/list/list-highlight"
import {Tile} from "../components/v2024/tile/tile"
import YoutubeVideo from "../components/v2024/video/youtube-video"
import FloatingBar from "../components/v2024/FloatingBar/floating-bar"
import ModalLogin from "../components/v2024/Login/modal-login"
import MenuModalState from "../components/v2024/Login/modal-login-state"
import { StaticImage } from "gatsby-plugin-image"
import TecalisLogo from "../new/resources/tecalis-white-green.svg"
import SEO from "../components/seo"

const SignFreePage = ({ data, pageContext }) => {
    const page = data.page
    const lang = pageContext.langKey

    const menuState = MenuModalState();

    const hideFloatingBar = () => {
        const footer = document.getElementsByClassName('footer--thin')[0]
        const footerElement = footer.getBoundingClientRect()
        const bar = document.getElementsByClassName('floating-bar')[0]
        const barElement = bar.getBoundingClientRect()
        if (barElement.bottom >= footerElement.top) {
            bar.classList.remove("show");
        } else  {
            bar.classList.add("show")
        }
    }

    const win = (typeof window !== "undefined" ? window : null)
    win?.addEventListener("scroll", hideFloatingBar)

    return (
        <NewLayout
            pageContext={pageContext}
            noHeader
            thinFooter
        >
            <SEO
                title={page.seo.title}
                description={page.seo.meta_description}
                image={page.seo.image_2}
                lang={pageContext?.langKey}
                translates={pageContext?.translates}
            />
            <MainSection50 sectionColor="purple-dark" sectionType="color" sectionSize="lg" childrenLeft={
                <>
                    <img src={TecalisLogo} alt="Tecalis" title="Tecalis" />
                    <h1 className="merriweather">{page.hero_title}</h1>
                    <div className="container__text">
                        <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                    </div>
                    <button onClick={menuState.openMenu} className="button button--ghost button--xl">
                        {page.hero_button.text}
                    </button>
                </>
            }
                childrenRight={
                    <div className="container__50__media" data-aos="fade-left">
                        <YoutubeVideo preview={page.hero_video_preview} video={page.hero_video} />
                    </div>
                }
            />
            <MainSection100>
                <h2>{page.explanation_content.title}</h2>
                <div className="container__text container__text--18">
                    <ReactMarkdown children={page.explanation_content.description} rehypePlugins={[rehypeRaw]} />
                </div>
                <GroupImageGroup itemGroup={page.explanation_items} gridItems="5" small animated />
            </MainSection100>
            <MainSection50
                sectionColor="gray"
                sectionType="color"
                sectionSize="md"
                childrenLeft={
                    <>
                        <h2>{page.how_works_title}</h2>
                        <ListHighlight items={page.how_works_items} />
                    </>
                }
                childrenRight={
                    <div data-aos="fade-left">
                        <lottie-player src={page.how_works_image?.localFile.publicURL} background="transparent" speed="1" loop autoplay />
                    </div>
                }
            />
            <MainSection100>
                <Tile title={page.tile_free.title} body={page.tile_free.description} button={page.tile_free.button} buttonAction={menuState.openMenu} />
            </MainSection100>

            <FloatingBar
                text={<ReactMarkdown children={page.need_more_text} rehypePlugins={[rehypeRaw]} />}
                button={
                    <>
                        <button onClick={menuState.toggleMenu} className="button button--secondary">
                            {page.need_more_button.text}
                        </button>
                    </>
                }
            />

            <ModalLogin state={menuState} lang={lang} plan={page.plan_free_form} selectedPlan="free" />

        </NewLayout>
    )
}

export default SignFreePage

export const signFreeQuery = graphql`
    query {
    page: strapi2024LandingSignFreeAds {
        seo {
            title
            meta_description
            image_2 {
                localFile {
                    publicURL
                }
            }
        }
        hero_title
        hero_description
        hero_button {
            text
            url
            blank
        }
        hero_video
        hero_video_preview {
            name
            alternativeText
            localFile {
                childImageSharp {
                    gatsbyImageData(
                    width: 608
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                    )
                }
            }
        }
        hero_image {
            name
            alternativeText
            localFile {
            childImageSharp {
                gatsbyImageData(
                width: 442
                quality: 100
                placeholder: BLURRED
                formats: [WEBP]
                breakpoints: [339, 402, 410]
                )
            }
            }
        }
        explanation_content {
            title
            description
        }
        explanation_items {
            title
            description
            image {
            name
            alternativeText
            localFile {
                childImageSharp {
                gatsbyImageData(
                    width: 60
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                )
                }
            }
            }
        }
        how_works_title
        how_works_items {
            order
            title
            description
        }
        how_works_image {
            name
            alternativeText
            localFile {
                publicURL
            }
        }
        tile_free {
            title
            description
            button {
                text
                url
            }
        }
        need_more_text
        need_more_button {
            text
            url
        }
        plan_free_form {
            form_email
            or_sign_up
            login_text
            form_name
            form_tc_description
            form_repassword	
            form_tc_description	
            form_password
            title	
            description
            button_google
            button_email
            form_lastname
            signup_text	
            button_signup
            button_login
            remember_me	
            forgot_password
            title_login	
            description_login
            form_tc_info
        }
    }
}
`
